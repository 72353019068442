<template>
    <div class="loading">
        <div class="sk-three-bounce">
            <div class="sk-bounce-1 sk-child"></div>
            <div class="sk-bounce-2 sk-child"></div>
            <div class="sk-bounce-3 sk-child"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loading',
}
</script>

<style lang="less" scoped>
.sk-three-bounce {
    margin: auto;
    text-align: center;

    .sk-child {
        width: 7px;
        height: 7px;
        background-color: #FD7C61;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
        animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    }

    .sk-bounce-1 {
        animation-delay: -0.32s;
    }

    .sk-bounce-2 {
        animation-delay: -0.16s;
    }

}

@keyframes sk-three-bounce {

    0%,
    80%,
    100% {
        transform: scale(0.5);
    }

    40% {
        transform: scale(1);
    }
}
</style>