

<template>
    <div class="user_message">
        <div class="no_have_value" ref="no_have_value">
            <!-- username输入框 -->
            <div class="user_message_content user_message_text" v-if="info.inputType == inputType.username">
                <div class="input_wrap">
                    <input type="text" @keydown="listenEnter" v-model="username" maxlength="50"
                        :placeholder="$t('NickChatStep4_ItemTitle')">
                    <div v-if="error" class="error_text">{{ error }}</div>
                </div>
                <div style="height: 10px;"></div>
                <div class="submit_bottom" @click="goNext" :class="{ 'can_not_submit': !canSendUsername }"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- email输入框 -->
            <div class="user_message_content user_message_text" v-if="info.inputType == inputType.email">
                <div class="input_wrap">
                    <div class="checking_email" v-if="checkingEmail">
                        <loading-icon></loading-icon>
                    </div>
                    <input type="text" @keydown="listenEnter" v-model="email" :placeholder="$t('NickChatStep73_ItemTitle')">
                    <div v-if="error" class="error_text">{{ error }}</div>
                </div>
                <div style="height: 10px;"></div>
                <div class="submit_bottom" @click="goNext" :class="{ 'can_not_submit': !canSendEmail }"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- 多选框 -->
            <div class="user_message_content user_message_text" v-if="info.inputType == inputType.helpCheckout">
                <div class="choose_title">{{ $t('NickMutiple_ItemTitle') }}</div>
                <div class="button_list" v-for="(item, ind) in info.checkList" @click="chooseCheckout(item)" :key="ind">
                    <choose-button :text="item" :isChoose="chooseList.includes(item)"></choose-button>
                </div>
                <div class="submit_bottom" @click="goNext" :class="{ 'can_not_submit': !canNext() }"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- 是否同意隐私条款的选择框 -->
            <div class="user_message_content user_message_text" v-if="info.inputType == inputType.agreeChoose">
                <div class="button_list" v-for="(item, ind) in info.checkList" @click="changeAgree(item)" :key="ind">
                    <choose-button :text="item" :isChoose="chooseList.includes(item)"></choose-button>
                </div>
            </div>
            <!-- 单选框 -->
            <div class="user_message_content user_message_text" v-if="info.inputType == inputType.radio">
                <div class="button_list" v-for="(item, ind) in info.checkList" @click="goNext(item)" :key="ind">
                    <choose-button :text="item"></choose-button>
                </div>
            </div>
            <!-- 时间选择器 -->
            <div class="user_message_content" v-if="info.inputType == inputType.timeSelect">
                <div class="picker_wrap">
                    <van-picker ref="timeSelect" item-height="34" visible-item-count="5" :columns="timeColumns" />
                </div>
                <div class="submit_bottom" @click="goNext"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- 年龄选择器 -->
            <div class="user_message_content" v-if="info.inputType == inputType.ageSelect">
                <div class="picker_wrap">
                    <van-picker ref="ageSelect" item-height="34" visible-item-count="3" :columns="ageColumns" />
                </div>
                <div class="submit_bottom" @click="goNext"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- 身高选择器 -->
            <div class="user_message_content" v-if="info.inputType == inputType.heightSelect">
                <div class="picker_wrap">
                    <van-picker v-show="isMetric" ref="metricHeightSelect" @change="heightMetricChange" item-height="34"
                        visible-item-count="3" :columns="heightMetricColumns" />
                    <van-picker v-show="!isMetric" ref="imperialHeightSelect" @change="heightImperialChange"
                        item-height="34" visible-item-count="3" :columns="heightColumns" />
                    <div class="change_metric_button">
                        <div @click="changeIsMetric(true)" class="is_metric" :class="{ 'active': isMetric }">cm</div>
                        <div @click="changeIsMetric(false)" class="is_not_metric" :class="{ 'active': !isMetric }">in</div>
                    </div>
                </div>
                <div class="submit_bottom" @click="goNext"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- 体重选择器 -->
            <div class="user_message_content" v-if="info.inputType == inputType.weightSelect">
                <div class="picker_wrap ">
                    <!-- 公制体重 -->
                    <div class="picker_inner" v-show="isMetric">
                        <div class="picker_inner_item">
                            <van-picker ref="weightMetricIntegerSelect" @change="weightMetricChange" item-height="34"
                                visible-item-count="3" :columns="weightMetricIntegerColumns" />
                        </div>
                        <div class="radix_point">.</div>
                        <div class="picker_inner_item">
                            <van-picker ref="weightMetricDecimalSelect" @change="weightMetricChange" item-height="34"
                                visible-item-count="3" :columns="weightMetricDecimalColumns" />
                        </div>
                        <div class="unit">kg</div>
                    </div>
                    <!-- 英制体重 -->
                    <div class="picker_inner" v-show="!isMetric">
                        <div class="picker_inner_item">
                            <van-picker ref="weightImperialIntegerSelect" @change="weightImperialChange" item-height="34"
                                visible-item-count="3" :columns="weightImperialIntegerColumns" />
                        </div>
                        <div class="radix_point">.</div>
                        <div class="picker_inner_item">
                            <van-picker ref="weightImperialDecimalSelect" @change="weightImperialChange" item-height="34"
                                visible-item-count="3" :columns="weightImperialDecimalColumns" />
                        </div>
                        <div class="unit">lbs</div>
                    </div>
                    <div class="change_metric_button">
                        <div @click="changeIsMetric(true)" class="is_metric" :class="{ 'active': isMetric }">kg</div>
                        <div @click="changeIsMetric(false)" class="is_not_metric" :class="{ 'active': !isMetric }">lb</div>
                    </div>
                </div>
                <div class="submit_bottom" @click="goNext"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
            <!-- 目标体重选择器 -->
            <div class="user_message_content" v-if="info.inputType == inputType.targetWeightSelect">
                <div class="picker_wrap ">
                    <!-- 公制体重 -->
                    <div class="picker_inner" v-show="isMetric">
                        <div class="picker_inner_item">
                            <van-picker ref="targetWeightMetricIntegerSelect" item-height="34" visible-item-count="3"
                                :columns="targetWeightMetricIntegerColumns" />
                        </div>
                        <div class="radix_point">.</div>
                        <div class="picker_inner_item">
                            <van-picker ref="targetWeightMetricDecimalSelect" item-height="34" visible-item-count="3"
                                :columns="weightMetricDecimalColumns" />
                        </div>
                        <div class="unit">kg</div>
                    </div>
                    <!-- 英制体重 -->
                    <div class="picker_inner" v-show="!isMetric">
                        <div class="picker_inner_item">
                            <van-picker ref="targetWeightImperialIntegerSelect" item-height="34" visible-item-count="3"
                                :columns="targetWeightImperialIntegerColumns" />
                        </div>
                        <div class="radix_point">.</div>
                        <div class="picker_inner_item">
                            <van-picker ref="targetWeightImperialDecimalSelect" item-height="34" visible-item-count="3"
                                :columns="weightImperialDecimalColumns" />
                        </div>
                        <div class="unit">lbs</div>
                    </div>
                </div>
                <div class="submit_bottom" @click="goNext"> {{
                    $t('NickChatSend_ItemTitle') }}
                </div>
            </div>
        </div>
        <transition name="van-fade">
            <div v-if="info.value" class="has_value">
                <div class="has_value_content">
                    {{ info.value }}
                </div>
            </div>
        </transition>
        <van-popup v-model="alertShow">
            <div class="alert_wrapper">
                <div class="alert_wrapper_content">{{ alertText }}</div>
                <div class="alert_wrapper_line"></div>
                <div class="alert_wrapper_button" @click="alertShow = false">{{ $t('Tracker_Text_Button') }}</div>
            </div>
        </van-popup>
        <van-popup v-model="showError" round>
            <div class="result_alert_content">
                <div class="describe">{{ $t('MustAgreePrivacyPolicy') }}
                </div>
                <div class="button" @click="closeError">{{ $t('ok') }}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import ChooseButton from '@/components/sign_components/choose_button.vue';
import LoadingIcon from '@/components/sign_components/loading.vue';
import { requestRegister } from '@/api/index.js';
import { common } from '@/assets/utils/common.js'
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);

export default {
    name: 'user_message',
    components: {
        ChooseButton, LoadingIcon
    },
    props: {
        signInfo: {
            type: Object,
            default: () => { },
        },
        info: {
            type: Object,
            default: () => { },
        },
        isMetric: {
            type: Boolean,
            default: () => false,
        },
        weightMax: {
            type: Number,
            default: () => 0,
        },
        proposeWeight: {
            type: String,
            default: () => '0',
        }
    },
    data() {
        return {
            showError: false,
            inputType: {
                username: 'username',
                email: 'email',
                helpCheckout: 'helpCheckout',
                agreeChoose: 'agreeChoose',
                nextButton: 'nextButton',
                radio: 'radio',
                timeSelect: 'timeSelect',
                ageSelect: 'ageSelect',
                heightSelect: 'heightSelect',
                weightSelect: 'weightSelect',
                targetWeightSelect: 'targetWeightSelect',
                // 疾病要单独列出来，可以多选，点击none的时候又是单选
                disease: 'disease',
                number: 'number',
            },
            // 点击疾病展示对应的弹窗
            alertShow: false,
            // 弹窗的文案
            alertTextList: [
                this.$t('diabetes_warning'),
                this.$t('anorexia_warning'),
                this.$t('gout_warning'),
                this.$t('pregnant_warning'),
                this.$t('medicine_warning'),
                this.$t('medicine_warning'),
            ],
            // 弹窗内容
            alertText: '',
            alertIndex: -1,
            // 年龄列表
            ageColumns: [
                {
                    values: [],
                    defaultIndex: 9,
                },
            ],
            // 时间列表
            timeColumns: [
                {
                    values: [],
                    defaultIndex: 5,
                },
                {
                    values: [],
                    defaultIndex: 0,
                },
                {
                    values: ['AM', 'PM'],
                    defaultIndex: 1,
                },
            ],
            // 身高英制转公制的列表
            heightImperialToMetric: [],
            // 身高信息
            heightInfo: [],
            //  公制身高列表
            heightMetricColumns: [
                {
                    values: [],
                    defaultIndex: 55,
                },
            ],
            // 英制身高列表
            heightColumns: [
                {
                    values: [],
                    defaultIndex: 1,
                },
                {
                    values: [],
                    defaultIndex: 9,
                },
            ],
            // 公制体重整数部分
            weightMetricIntegerColumns: [
                {
                    values: [],
                    defaultIndex: 0,
                },
            ],
            // 英制体重整数部分
            weightImperialIntegerColumns: [
                {
                    values: [],
                    defaultIndex: 0,
                },
            ],
            // 公制体重小数部分
            weightImperialDecimalColumns: [
                {
                    values: [],
                    defaultIndex: 0,
                },
            ],
            // 英制体重小数部分
            weightMetricDecimalColumns: [
                {
                    values: [],
                    defaultIndex: 0,
                },
            ],
            // 目标公制体重整数部分
            targetWeightMetricIntegerColumns: [
                {
                    values: [],
                    defaultIndex: 0,
                },
            ],
            // 目标英制体重整数部分
            targetWeightImperialIntegerColumns: [
                {
                    values: [],
                    defaultIndex: 0,
                },
            ],
            // 多选框选中的内容
            chooseList: [],
            // 用户名
            username: '',
            // 用户email
            email: '',
            // 是否可以发送email
            canSendEmail: false,
            // 正在发送中
            checkingEmail: false,
            // 输入框错误的内容
            error: '',
            // 不能进行下一步的多选
            canNotNextText: [
                this.$t('NickChatStep51_ItemTitle'),
                this.$t('NickChatStep53_ItemTitle'),
            ],
            // 是否可以发送username
            canSendUsername: false,
        }
    },
    created() {
        this.initColumns();
    },
    mounted() {
        this.initHeight();
        this.initFocus();
    },
    methods: {
        closeError() {
            this.showError = false;
            this.chooseList = [];
        },
        changeAgree(text) {
            this.chooseList = [text];
            this.goNext(text);
        },
        // 初始化焦点
        initFocus() {
            if (this.info.inputType != this.inputType.email && this.info.inputType != this.inputType.username) return;
            let inputs = document.getElementsByTagName('input');
            if (inputs.length > 0) {
                let input = inputs[inputs.length - 1];
                // alert(input);
                if (input) {
                    this.$nextTick(() => {
                        // 自动聚焦
                        input.focus();
                    })
                }
            }
        },
        // 初始化选项
        initColumns() {
            switch (this.info.inputType) {
                case this.inputType.timeSelect:
                    // time
                    for (let i = 1; i <= 12; i++) {
                        if (i < 10) {
                            this.timeColumns[0].values.push(`0${i}`)
                        } else {
                            this.timeColumns[0].values.push(`${i}`)
                        }
                    }
                    for (let i = 0; i < 4; i++) {
                        let target = i * 15 == 0 ? '00' : (i * 15).toString();
                        this.timeColumns[1].values.push(target);
                    }
                    break;
                case this.inputType.ageSelect:
                    // age 
                    for (let i = 18; i <= 82; i++) {
                        this.ageColumns[0].values.push(i);
                    }
                    break;
                case this.inputType.heightSelect:
                    var heightObject = require('@/assets/json/height_cm.json');
                    this.heightInfo = heightObject.pdHeight;
                    // height
                    this.heightInfo.forEach(item => {
                        for (let key in item) {

                            // 设置公制列表
                            this.heightMetricColumns[0].values.push(`${key} cm`);
                            // 设置英制列表
                            let ftValue = `${item[key]['ft']} ft`;
                            if (this.heightColumns[0].values.indexOf(ftValue) < 0) {
                                this.heightColumns[0].values.push(ftValue);
                            }
                            let inValue = `${item[key]['in']} in`;
                            if (this.heightColumns[1].values.indexOf(inValue) < 0) {
                                this.heightColumns[1].values.push(inValue);
                            }

                            // 设置英制转公制列表
                            var imperialUnits = item[key]['ft'] + ',' + item[key]['in'];
                            let itemMap = new Map();
                            itemMap.set(imperialUnits, key);
                            this.heightImperialToMetric.push(itemMap);
                        }
                    })

                    break;
                case this.inputType.weightSelect:
                    // weight
                    // 公制体重整数部分
                    for (let i = 41; i <= 300; i++) {
                        this.weightMetricIntegerColumns[0].values.push(i);
                    }
                    // 英制体重整数部分
                    for (let i = 89; i <= 661; i++) {
                        this.weightImperialIntegerColumns[0].values.push(i);
                    }
                    // 英制、公制体重小数部分
                    for (let i = 0; i <= 9; i++) {
                        this.weightImperialDecimalColumns[0].values.push(i);
                        this.weightMetricDecimalColumns[0].values.push(i);
                    }
                    // 设置体重默认值
                    this.weightMetricIntegerColumns[0].defaultIndex = 91 - 41;
                    var targetData = (91 * common.lbs).toFixed(1).toString().split('.');
                    this.weightImperialIntegerColumns[0].defaultIndex = targetData[0] - 89;
                    this.weightImperialDecimalColumns[0].defaultIndex = targetData[1] - 0;
                    break;
                case this.inputType.targetWeightSelect:
                    var min = Math.min(this.weightMax, this.proposeWeight.split('.')[0]);
                    if (this.isMetric) {
                        for (let i = 40; i <= this.weightMax - 1; i++) {
                            this.targetWeightMetricIntegerColumns[0].values.push(i);
                        }
                        this.targetWeightMetricIntegerColumns[0].defaultIndex = min - 40;
                    } else {
                        // 英制体重整数部分
                        for (let i = 88; i <= this.weightMax - 1; i++) {
                            this.targetWeightImperialIntegerColumns[0].values.push(i);
                        }
                        this.targetWeightImperialIntegerColumns[0].defaultIndex = min - 88;
                    }
                    // 英制、公制体重小数部分
                    for (let i = 0; i <= 9; i++) {
                        this.weightImperialDecimalColumns[0].values.push(i);
                        this.weightMetricDecimalColumns[0].values.push(i);
                    }
                    this.weightImperialDecimalColumns[0].defaultIndex = this.proposeWeight.split('.')[1].slice(0, 1) - 0;
                    this.weightMetricDecimalColumns[0].defaultIndex = this.proposeWeight.split('.')[1].slice(0, 1) - 0;
                    break;

            }
        },
        // 选中多选框触发的事件
        chooseCheckout(text) {
            let none = this.$t('NickChatStep55_ItemTitle');
            // 当前点击的按钮包含在选中的列表中
            if (this.chooseList.includes(text)) {
                this.chooseList = this.chooseList.filter(item => text != item);
            } else {
                // 如果点击none按钮
                if (text == none) {
                    this.chooseList = [none];
                } else {
                    // 点击的不是none按钮，先添加，再去除none
                    this.chooseList.push(text);
                    if (this.info.key == 'conditions') {
                        // 去除掉none
                        if (this.chooseList.includes(none)) {
                            this.chooseList = this.chooseList.filter(item => none != item);
                        }
                        // 打开弹窗
                        this.alertShow = true;
                        // 写入内容
                        this.alertText = this.alertTextList[this.info.checkList.indexOf(text)];
                    }
                }
            }

        },
        listenEnter(e) {
            if (e.keyCode == 13) {
                this.goNext();
                document.activeElement.blur();
            }
        },
        // 初始化元素高度
        initHeight() {
            this.$nextTick(() => {
                if (!this.$refs['no_have_value']) return;
                let height = this.$refs['no_have_value'].offsetHeight + 'px';
                if (this.info.value) {
                    this.$refs['no_have_value'].style.height = 0;
                } else {
                    this.$refs['no_have_value'].style.height = height;
                }
            })
        },
        // 点击进行下一步
        async goNext(text) {
            let info = JSON.parse(JSON.stringify(this.info));
            if (this.info.inputType == this.inputType.username) {
                if (!this.canSendUsername) return;
                info.value = this.username.trim();
            } else if (this.info.inputType == this.inputType.helpCheckout) {
                if (!this.canNext()) return;
                info.value = this.chooseList.join('; ') + '.';
            } else if (this.info.inputType == this.inputType.radio) {
                if (!text) return;
                info.value = text;
            } else if (this.info.inputType == this.inputType.timeSelect) {
                let value = this.$refs.timeSelect.getValues();
                info.value = (value[0] - 0) + ':' + value[1] + ' ' + value[2];
            } else if (this.info.inputType == this.inputType.ageSelect) {
                let value = this.$refs.ageSelect.getValues();
                info.value = value[0].toString();
            } else if (this.info.inputType == this.inputType.heightSelect) {
                let targetValue;
                let toMetre;
                if (this.isMetric) {
                    let value = this.$refs.metricHeightSelect.getValues()[0];
                    toMetre = value.split(' ')[0];
                    targetValue = value.split(' ').join('');
                } else {
                    let value = this.$refs.imperialHeightSelect.getValues();
                    toMetre = this.imperialToMetric(value);
                    targetValue = value.map(item => item.split(' ').join('')).join(' ');
                }
                info.isMetric = this.isMetric;
                info.value = targetValue;
                info.toMetre = toMetre;
                info.unit = this.isMetric ? 0 : 1;
            } else if (this.info.inputType == this.inputType.weightSelect) {
                let value;
                if (this.isMetric) {
                    let value1 = this.$refs.weightMetricIntegerSelect.getValues()[0];
                    let value2 = this.$refs.weightMetricDecimalSelect.getValues()[0];
                    value = value1 + '.' + value2 + 'kg';
                } else {
                    let value1 = this.$refs.weightImperialIntegerSelect.getValues()[0];
                    let value2 = this.$refs.weightImperialDecimalSelect.getValues()[0];
                    value = value1 + '.' + value2 + 'lbs';
                }
                info.isMetric = this.isMetric;
                info.value = value;
                info.unit = this.isMetric ? 0 : 1;
            } else if (this.info.inputType == this.inputType.targetWeightSelect) {
                let value;
                if (this.isMetric) {
                    let value1 = this.$refs.targetWeightMetricIntegerSelect.getValues()[0];
                    let value2 = this.$refs.targetWeightMetricDecimalSelect.getValues()[0];
                    value = value1 + '.' + value2 + 'kg';
                } else {
                    let value1 = this.$refs.targetWeightImperialIntegerSelect.getValues()[0];
                    let value2 = this.$refs.targetWeightImperialDecimalSelect.getValues()[0];
                    value = value1 + '.' + value2 + 'lbs';
                }
                info.isMetric = this.isMetric;
                info.value = value;
                info.unit = this.isMetric ? 0 : 1;
            } else if (this.info.inputType == this.inputType.email) {
                if (this.canSendEmail && !this.checkingEmail) {
                    this.checkingEmail = true;
                    let signInfo = JSON.parse(JSON.stringify(this.signInfo));
                    signInfo.email = this.email;
                    let success = await this.requestEmail(signInfo);
                    this.checkingEmail = false;
                    if (success) {
                        info.value = this.email;
                    } else {
                        return;
                    }
                } else {
                    return;
                }
            } else if (this.info.inputType == this.inputType.agreeChoose) {
                if (!text) return;
                if (text == this.$t('Disagree')) {
                    this.showError = true;
                    return;
                }
                info.value = text;
            } else {
                return;
            }
            this.$emit('next', info);
            this.$refs['no_have_value'].style.height = 0;
        },
        // 通过英制身高获取公制身高
        imperialToMetric(values) {
            let key = values.map(item => item.split(' ')[0]).join(',');
            let target = '';
            for (let i = 0; i < this.heightImperialToMetric.length; i++) {
                let item = this.heightImperialToMetric[i];
                if (item.get(key)) {
                    target = item.get(key);
                    break;
                }
            }
            return target;
        },
        // 英制身高变化
        heightImperialChange() {
            let hasUnits = false;
            let values = this.$refs.imperialHeightSelect.getValues();
            let key = values.map(item => item.split(' ')[0]).join(',');
            for (let i = 0; i < this.heightImperialToMetric.length; i++) {
                let item = this.heightImperialToMetric[i];
                if (item.get(key)) {
                    hasUnits = true;
                    this.$refs.metricHeightSelect.setColumnValue(0, `${item.get(key)} cm`);
                    break;
                }
            }
            if (!hasUnits) {
                this.$refs.metricHeightSelect.setColumnValue(0, this.heightMetricColumns[0].values[this.heightMetricColumns[0].values.length - 1]);
                let lastIn = 0;
                for (let key in this.heightInfo[this.heightInfo.length - 1]) { lastIn = `${this.heightInfo[this.heightInfo.length - 1][key].in} in` }
                this.$refs.imperialHeightSelect.setColumnValue(1, lastIn);
                return;
            }
        },
        // 公制身高变化
        heightMetricChange() {
            let value = this.$refs.metricHeightSelect.getValues()[0].split(' ')[0];
            this.heightInfo.forEach(item => {
                if (item[value]) {
                    this.$refs.imperialHeightSelect.setColumnValue(0, `${item[value]['ft']} ft`);
                    this.$refs.imperialHeightSelect.setColumnValue(1, `${item[value]['in']} in`);
                }
            });

        },
        // 英制体重变化
        weightImperialChange() {
            let value = this.$refs.weightImperialIntegerSelect.getValues()[0] + '.' + this.$refs.weightImperialDecimalSelect.getValues()[0];
            let targetValue = ((value / common.lbs).toFixed(1)).toString();
            this.$refs.weightMetricIntegerSelect.setColumnValue(0, parseInt(targetValue.split('.')[0]))
            this.$refs.weightMetricDecimalSelect.setColumnValue(0, parseInt(targetValue.split('.')[1].toString()))
        },
        // 公制体重变化
        weightMetricChange() {
            let value = this.$refs.weightMetricIntegerSelect.getValues()[0] + '.' + this.$refs.weightMetricDecimalSelect.getValues()[0];
            let targetValue = ((value * common.lbs).toFixed(1)).toString();
            this.$refs.weightImperialIntegerSelect.setColumnValue(0, parseInt(targetValue.split('.')[0]))
            this.$refs.weightImperialDecimalSelect.setColumnValue(0, parseInt(targetValue.split('.')[1].toString()))
        },
        // 切换公英制
        changeIsMetric(isMetric) {
            this.$emit('changeIsMetric', isMetric);
        },
        // 请求接口验证email
        async requestEmail(signInfo) {
            let res = await requestRegister(signInfo);
            if (res.errorCode != null) {
                let errorText = this.$t('NetworkError');
                if (res.errorCode == 4003) {
                    errorText = this.$t('email_in_user_toast');
                }
                Toast({
                    className: 'sign_toast',
                    message: errorText,
                });
                return;
            }
            if (!res.token) return;
            this.$changeLocal.setItem('email', this.email);
            this.$changeLocal.setItem('localPlan', res);
            this.$store.commit('setSignToken', res.token);
            this.$changeCookie.setCookie('registerToken', res.token, process.env.VUE_APP_TOKEN_TIMEOUT);
            return true;
        },
        // checkbox是否可以进行下一步
        canNext() {
            var canNext = true;
            if (this.chooseList.length == 0) {
                canNext = false;
            } else {
                this.chooseList.forEach(item => {
                    if (this.canNotNextText.includes(item)) {
                        canNext = false;
                    }
                })
            }
            return canNext;
        }

    },
    watch: {
        weightMax() {
            var min = Math.min(this.weightMax, this.proposeWeight.split('.')[0]);
            if (this.isMetric) {
                for (let i = 40; i <= this.weightMax - 1; i++) {
                    this.targetWeightMetricIntegerColumns[0].values.push(i);
                }
                this.targetWeightMetricIntegerColumns[0].defaultIndex = min - 40;
            } else {
                // 英制体重整数部分
                for (let i = 88; i <= this.weightMax - 1; i++) {
                    this.targetWeightImperialIntegerColumns[0].values.push(i);
                }
                this.targetWeightImperialIntegerColumns[0].defaultIndex = min - 88;
            }
            // 英制、公制体重小数部分
            for (let i = 0; i <= 9; i++) {
                this.weightImperialDecimalColumns[0].values.push(i);
                this.weightMetricDecimalColumns[0].values.push(i);
            }
            if (this.proposeWeight) {
                this.weightImperialDecimalColumns[0].defaultIndex = this.proposeWeight.split('.')[1].slice(0, 1) - 0;
                this.weightMetricDecimalColumns[0].defaultIndex = this.proposeWeight.split('.')[1].slice(0, 1) - 0;
            }

        },
        username(val) {
            this.canSendUsername = common.checkUsername(val);
        },
        email(val) {
            this.canSendEmail = common.isEmail(val);
        }
    }
}
</script>

<style scoped lang="less">
.user_message {
    margin: 15px 18px 15px 32px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    .result_alert_content {
        padding: 30px 25px 26px;
        width: 335px;
        box-sizing: border-box;
        text-align: center;


        .describe {
            font-size: 20px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #444444;
            line-height: 27px;
        }

        .fail_text {
            font-size: 12px;
            font-family: 'AvenirNext';
            font-weight: 500;
            color: #E85345;
            line-height: 16px;
            margin: 10px;
        }

        .button {
            height: 55px;
            background: #FD7C61;
            border-radius: 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #FFFFFF;
            line-height: 25px;
            cursor: pointer;
            margin-top: 20px;
        }
    }

    .user_message_text {

        .input_wrap {
            width: 246px;
            position: relative;

            .checking_email {
                position: absolute;
                right: 256px;
                width: 25px;
                height: 25px;
                top: 20px;
                transform: translate(0, -50%);
            }


            .error_text {
                margin-top: 10px;
                font-size: 12px;
                font-family: 'AvenirNext';
                font-weight: 500;
                color: #E85345;
                line-height: 16px;
                text-align: left;

            }
        }

    }

    .user_message_content {
        max-width: 460px;
        width: calc(100vw - 50px);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }

    input {
        width: 246px;
        height: 40px;
        border: 1px solid #FD7C61;
        border-radius: 12px;
        background: #F9F9F9;
        padding: 0 16px;
        box-sizing: border-box;
        caret-color: #FD7C61;
        color: #444;
    }

    input::-webkit-input-placeholder {
        color: #bbb;
        font-size: 14px;
    }

    .no_have_value {
        transition: all 0.25s linear;
        overflow: hidden;
    }

    .has_value {
        max-width: calc(100vw - 50px);

        .has_value_content {
            background: #2B7DF7;
            border-radius: 12px 12px 0px 12px;
            padding: 10px 15px;
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            line-height: 21px;
            font-family: 'AvenirNext';
            word-wrap: break-word;
        }
    }

    .submit_bottom {
        width: 74px;
        height: 45px;
        background: linear-gradient(270deg, #FEBA4D 0%, #F77976 100%);
        border-radius: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-family: 'AvenirNext';
        font-weight: 600;
        color: #FFFFFF;
        line-height: 20px;
        cursor: pointer;
    }

    .can_not_submit {
        opacity: 0.3;
    }

    .choose_title {
        margin-bottom: 15px;
    }

    .picker_wrap {
        border-radius: 10px;
        border: 1px solid #2B7DF7;
        margin: 20px auto;
        overflow: hidden;
        z-index: 3;
        width: 100%;
        box-sizing: border-box;
    }

    .change_metric_button {
        width: 100px;
        height: 30px;
        border-radius: 30px;
        margin: 20px auto;
        display: flex;
        overflow: hidden;
        border: 1px solid #5C6892;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .change_metric_button div {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .change_metric_button div.active {
        color: #fff;
        background-color: #2B7DF7;
    }

    .picker_inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .picker_inner>div.picker_inner_item {
        flex: 1;
    }

    .change_metric_button div {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .change_metric_button div.active {
        color: #fff;
        background-color: #5C6892;
    }


    .unit,
    .radix_point {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 17px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;

    }

    .radix_point {
        margin-right: 0;
        font-weight: 900;
    }

    .alert_wrapper {
        width: calc(100vw - 40px);
        max-width: 500px;
        padding: 29px 25px 0;
        box-sizing: border-box;

        .alert_wrapper_content {
            font-size: 20px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #444444;
            line-height: 27px;
            margin-bottom: 35px;
        }

        .alert_wrapper_line {
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #EFEFEF 8%, #EFEFEF 93%, rgba(255, 255, 255, 0) 100%);
        }

        .alert_wrapper_button {
            height: 56px;
            line-height: 56px;
            font-size: 18px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #Fd7c61;
            cursor: pointer;
        }
    }

}

::v-deep .van-popup {
    border-radius: 20px;
}

::v-deep .van-picker__frame {
    background-color: #efefef;
    border-radius: 10px;
    z-index: -1;
}

::v-deep .van-picker__frame::after {
    border: none;
}

::v-deep .van-picker {
    background-color: transparent;
    width: 100%;
    margin: 30px 0;
}

::v-deep .van-picker__columns {
    display: flex;
    justify-content: center;
}


::v-deep .van-ellipsis {
    font-size: 20px;
    font-family: 'AvenirNext';
    font-weight: 600;
    color: #333333;
    line-height: 20px;
}
</style>