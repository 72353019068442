

<template>
    <div class="coach_message">
        <!-- 左侧教练头像 -->
        <div class="coach_message_head">
            <loading-image
                v-if="info.contentType == contentType.text || info.contentType == contentType.loading || info.contentType == contentType.termsAndPrivacy"
                :imageUrl="`${$store.state.assetsUrl}/imgs/sign/coach_head.webp`" :borderRadius="'50%'"></loading-image>
        </div>
        <!-- 纯文本部分 -->
        <div class=" coach_message_content coach_message_text" v-if="info.contentType == contentType.text">
            <div class="coach_message_context">
                <span v-for="(item, ind) in contextList" :key="ind">
                    <span v-if="item.type == textType.linkText" :class="{ 'link_text': item.type == textType.linkText }">
                        <van-popover :overlay="true" placement="top" v-model="isShowPopover" trigger="click">
                            <alert-toast :type="info.linkType"></alert-toast>
                            <template #reference>
                                {{ item.content }}
                            </template>
                        </van-popover>
                    </span>
                    <span v-else @click="showPopover" :class="{ 'red_text': item.type == textType.redText, }">
                        {{ item.content }}
                    </span>
                </span>
            </div>
        </div>
        <!-- 协议部分 -->
        <div class=" coach_message_content coach_message_text" v-if="info.contentType == contentType.termsAndPrivacy">
            <div class="coach_message_context">
                <span>{{ this.$t('AgreePrivacyPolicy1') }}</span>
                <span @click="$router.push('/terms/app')" class="link_text">{{ this.$t('terms') }}</span>
                <span>{{ this.$t('and') }}</span>
                <span @click="$router.push('/privacy/app')" class="link_text">{{ this.$t('privacyPolicy') }}</span>
                <span>{{ this.$t('AgreePrivacyPolicy2') }}</span>
            </div>
        </div>
        <!-- 图片 -->
        <div class="coach_message_content coach_message_image" :class="{ 'hasBorder': showBorder }"
            :style="info.scale | getHeight('object')" v-else-if="info.contentType == contentType.image">
            <loading-image :imageUrl="info.imgUrl" :borderRadius="'13px'" @imageLoad="imageLoad"
                :height="info.scale | getHeight('string')"></loading-image>
        </div>
        <!-- bmi指数 -->
        <div class="coach_message_content coach_message_bmi" v-else-if="info.contentType == contentType.bmi">
            <!-- bmi标题 -->
            <div class="bmi_title">{{ $t('Register_Bmi_ItemTitle') }}</div>
            <!-- bmi中间的渐变线 -->
            <div class="bmi_line">
                <div class="line">
                    <div class="line_info" :style="{ 'left': `${percentage}%` }">
                        <div class="point_info" :style="{ '--borderColor': gradient(percentage) }">
                            {{ bmiCount.toFixed(1) }}
                        </div>
                        <div class="point" :style="{ 'border-color': gradient(percentage) }">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分类文案 -->
            <div class="bmi_classify">
                <div class="bmi_classify_normal">{{ $t('Register_Bmi_Normal_ItemTitle') }}</div>
                <div class="bmi_classify_overweight">{{ $t('Register_Bmi_Overweight_ItemTitle') }}</div>
                <div class="bmi_classify_obese">{{ $t('Register_Bmi_Obese_ItemTitle') }}</div>
            </div>
            <!-- 描述 -->
            <div class="bmi_describe" v-if="bmiCount > 24.9">
                <div class="bmi_describe_emoji">🤔</div>
                <div class="bmi_describe_text">
                    <div class="bmi_describe_title">
                        {{ $t('Register_Bmi_unhealthy_ItemTilte') }}
                    </div>
                    <div>
                        {{ $t('Register_Bmi_unhealthy_ItemContent') }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 文字+loading -->
        <div class="coach_message_content coach_message_text" v-else-if="info.contentType == contentType.loading">
            <div v-if="loadingIndex >= info.contentList.length" class="coach_message_context">
                <span v-for="(item, ind) in contextList" :key="ind">
                    <span @click="showPopover" :class="{ 'red_text': item.type == textType.redText, }">
                        {{ item.content }}
                    </span>
                </span>
            </div>
            <div v-else class="coach_loading coach_message_context">
                <span v-for="(item, ind)  in info.contentList" :key="ind">
                    <span v-if="ind == loadingIndex">
                        {{ item }}
                    </span>
                </span>
                <div class="coach_loading_info">
                    <loading-icon></loading-icon>
                </div>
            </div>

        </div>
        <!-- plan部分 -->
        <div class="coach_message_content coach_message_plan" v-else-if="info.contentType == contentType.plan">
            <div class="plan_info">
                <div class="chart_wrap">
                    <div class="timeline_chart_inner">
                        <plan-chart></plan-chart>
                    </div>
                </div>
                <div class="vague_plan">
                    <img :src="`${$store.state.assetsUrl}/imgs/sign/vague_plan.webp`" @error="imageError">
                </div>
                <div class="plan_button_wrap">
                    <div class="plan_button">
                        <!-- 按钮 -->
                        <spread-animation :text="$t('BuyProNormal_Continue_ItemTitle')" :startColor="'#fd7c61'"
                            :width="'100%'" :height="'51px'" :endColor="'#fd7c61'"
                            :callback="goPlanPage"></spread-animation>
                    </div>
                </div>

            </div>
        </div>
        <div v-else></div>
    </div>
</template>


<script>
import AlertToast from './alert_toast.vue';
import LoadingIcon from '@/components/sign_components/loading.vue';
import PlanChart from './plan_chart.vue';
import { myMixin } from '@/assets/mixin/index.js';
import { commonMixin } from '@/assets/mixin/common';
import { logFirebaseEvent } from '@/assets/log/log_manager.js'
import SpreadAnimation from '../spread_animation.vue';
import LoadingImage from '../loading_image.vue';

// line渐变开始颜色和结束的颜色
const startColor = '#83A1FF';
const endColor = '#FF7676';

export default {
    name: 'coach_message',
    mixins: [myMixin, commonMixin],
    components: {
        AlertToast,
        PlanChart,
        LoadingIcon,
        SpreadAnimation,
        LoadingImage,
    },
    props: {
        bmiCount: {
            type: Number,
            default: () => 30
        },
        info: {
            type: Object,
            default: () => { },
        },
        username: {
            type: String,
            default: () => '',
        },
    },
    filters: {
        getHeight: (scale, type) => {
            let width = Math.min(460, document.body.offsetWidth - 99);
            let height = width / scale;
            if (type == 'object') {
                return { "height": height + 'px' }
            }
            return height + 'px';
        }
    },
    data() {
        return {
            loadingIndex: 0,
            isShowPopover: false,
            percentage: 0,
            contextList: [],
            targetBMI: 25,
            showBorder: false,
            redTextList: [
                this.$t('RedText1'),
                this.$t('RedText2'),
                this.$t('RedText3'),
                this.$t('RedText4', { username: this.username }),
            ],
            contentType: {
                text: 'text',
                loading: 'loading',
                image: 'image',
                bmi: 'bmi',
                plan: 'plan',
                termsAndPrivacy: 'terms and privacy',
            },
            textType: {
                text: 'text',
                linkText: 'linkText',
                redText: 'redText',
            },
        }
    },
    created() {
        this.handleText();
        this.handelBMI();
        this.changeLoadingIndex();
    },
    mounted() { },
    methods: {
        // 子集出发图片加载事件
        imageLoad() {
            if (this.info.key == 'dataLine') {
                this.showBorder = true;
            }
        },
        // 点击进入到plan页面
        goPlanPage() {
            // 点击get plan的埋点
            logFirebaseEvent('F_Web_Dialogue_GetPlan');
            // 重置倒计时
            this.$store.dispatch('setCountdownCount', '600');
            this.goPath('/plan')
        },
        // 更改loading展示的下标
        changeLoadingIndex() {
            if (this.info.contentType != this.contentType.loading) return;
            if (this.loadingIndex == 4) {
                this.$emit('coachNext');
                return;
            }
            setTimeout(() => {
                this.loadingIndex++;
                this.changeLoadingIndex();
            }, 1000);
        },
        // 展示气泡
        showPopover() {
            this.isShowPopover = true;
        },
        // 处理content内容
        handleText() {
            if (this.info.contentType != this.contentType.text && this.info.contentType != this.contentType.loading) return;
            this.contextList = [];
            let text = this.info.content;
            if (this.info.linkStart) {
                this.handleLinkText(text);
            } else {
                this.handleRedText(text);
            }
        },
        // 处理链接
        handleLinkText(text) {
            let text1 = text.slice(0, this.info.linkStart);
            let text2 = text.slice(this.info.linkStart, this.info.linkStart + this.info.linkLength);
            let text3 = text.slice(this.info.linkStart + this.info.linkLength);
            this.contextList.push({
                content: text1,
                type: this.textType.text,
            })
            this.contextList.push({
                content: text2,
                type: this.textType.linkText,
            })
            this.contextList.push({
                content: text3,
                type: this.textType.text,
            })
        },
        // 处理红色文字
        handleRedText(text) {
            let hasRed = false;
            for (var i = 0; i < this.redTextList.length; i++) {
                const item = this.redTextList[i];
                if (text.indexOf(item) > 0) {
                    hasRed = true;
                    let text1 = text.split(item)[0];
                    let text2 = item;
                    let text3 = text.split(item)[1];
                    this.contextList.push({
                        content: text1,
                        type: this.textType.text,
                    })
                    this.contextList.push({
                        content: text2,
                        type: this.textType.redText,
                    })
                    this.handleRedText(text3);
                    break;
                }
            }
            if (!hasRed) {
                this.contextList.push({
                    content: text,
                    type: this.textType.text,
                })
            }
        },
        // 设置渐变颜色列表
        rgbToHex(r, g, b) {
            var hex = ((r << 16) | (g << 8) | b).toString(16);
            return "#" + new Array(Math.abs(hex.length - 7)).join("0") + hex;
        },
        hexToRgb(hex) {
            var rgb = [];
            for (var i = 1; i < 7; i += 2) {
                rgb.push(parseInt("0x" + hex.slice(i, i + 2)));
            }
            return rgb;
        },
        gradient(index) {
            // 将 hex 转换为rgb
            var sColor = this.hexToRgb(startColor),
                eColor = this.hexToRgb(endColor);

            // 计算R\G\B每一步的差值
            var rStep = (eColor[0] - sColor[0]) / 100,
                gStep = (eColor[1] - sColor[1]) / 100,
                bStep = (eColor[2] - sColor[2]) / 100;

            var gradientColorArr = [];
            for (var i = 0; i < 100; i++) {
                // 计算每一步的hex值
                gradientColorArr.push(this.rgbToHex(parseInt(rStep * i + sColor[0]), parseInt(gStep * i + sColor[1]), parseInt(bStep * i + sColor[2])));
            }
            return gradientColorArr[index];
        },
        // 处理BMI
        handelBMI() {
            if (this.info.contentType != this.contentType.bmi) return;
            let targetBMI = this.bmiCount;
            targetBMI = Math.max(24.9, targetBMI);
            targetBMI = Math.min(34.9, targetBMI);
            let percentage = Math.round((targetBMI - 24.9) / (34.9 - 24.9) * 100);
            percentage = Math.max(0, percentage);
            percentage = Math.min(99, percentage);
            this.percentage = percentage;
        }
    },
    watch: {
        info() {
            this.handleText();
        },
        bmiCount() {
            this.handelBMI();
        },
    },

}
</script>

<style scoped lang="less">
.coach_message {
    margin: 15px 18px;
    display: flex;
    font-size: 15px;

    .coach_message_head {
        width: 48px;
        height: 48px;
        margin-right: 15px;

        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    .coach_message_content {
        flex: 1;
        max-width: 460px;
    }

    .coach_message_text {
        display: flex;
        align-items: flex-start;

        @media (max-width:570px) {
            max-width: calc(100vw - 36px - 48px - 15px);
        }


        .coach_message_context {
            border-radius: 0 12px 12px 12px;
            text-align: left;
            padding: 10px 15px;
            background: #F6F6F6;
            font-family: 'AvenirNext';
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            max-width: 100%;
            box-sizing: border-box;

            span {
                word-wrap: break-word;
            }

            span.link_text {
                color: #5D99E9;
                border-bottom: 1px dashed #5D99E9;
                cursor: pointer;
            }

            span.red_text {
                color: #FE7579;
            }
        }
    }

    .coach_message_image {
        overflow: hidden;
        position: relative;
    }

    .hasBorder {
        border: 1px solid #ececec;
        box-sizing: border-box;
        border-radius: 13px;
    }

    @media (min-width: 500px) {
        .hasBorder {
            border: 2px solid #ececec;
        }
    }

    .coach_message_bmi {
        border-radius: 12px;
        border: 1px solid rgba(151, 151, 151, 0.18);
        padding: 15px;
        box-sizing: border-box;

        .bmi_title {
            font-size: 15px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #54575B;
            line-height: 20px;
            text-align: left;
        }

        .bmi_line {
            margin-top: 38px;

            .line {
                height: 5px;
                border-radius: 2px;
                background-image: linear-gradient(to right, #83A1FF, #FF7676);
                position: relative;
                margin: 50px auto 20px;

                .line_info {
                    position: absolute;
                    bottom: -2px;

                    .point_info {
                        height: 18px;
                        background-color: var(--borderColor);
                        position: absolute;
                        border-radius: 18px;
                        padding: 0 7px;
                        font-size: 12px;
                        bottom: 20px;
                        transform: translate(-50%, 0);
                        left: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 18px;
                        color: #fff;
                    }

                    .point_info::after {
                        content: '';
                        width: 0;
                        display: block;
                        height: 0;
                        border: 8px solid transparent;
                        border-top: 6px solid var(--borderColor);
                        position: absolute;
                        bottom: -14px;
                    }

                    .point {
                        width: 5px;
                        height: 5px;
                        border: 2px solid;
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }

        .bmi_classify {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: Avenir-Book, Avenir;
            font-weight: normal;
            color: #54575B;
            line-height: 16px;
        }

        .bmi_describe {
            display: flex;
            margin-top: 15px;
            padding: 10px;
            border-radius: 12px;
            background-color: #F3CAC2;

            .bmi_describe_emoji {
                font-size: 15px;
                margin-right: 2px;
            }

            .bmi_describe_text {
                text-align: left;
                font-size: 14px;
                font-family: 'AvenirNext';
                font-weight: 500;
                color: #54575B;
                line-height: 19px;

                .bmi_describe_title {
                    font-weight: 600;
                }
            }
        }
    }

    .coach_loading {
        display: flex;

        .coach_loading_info {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }



    .plan_info {
        width: 100%;
        box-shadow: 0 0 10px 0 #ddd;
        border-radius: 20px;
        padding-bottom: 18px;
        overflow: hidden;

        .chart_wrap {
            position: relative;
            height: 210px;
            background: #FFFFFF;
            box-sizing: border-box;
            padding-top: 50%;

            .timeline_chart_inner {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }


        .vague_plan {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                overflow: hidden;
            }
        }

        .plan_button_wrap {
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 0 15px;
        }

        .plan_button {
            max-width: 256px;
            width: 100%;
            flex: 1;
            height: 51px;
            margin-top: 10px;
            background-color: #fd7c61;
            color: #fff;
            cursor: pointer;
            margin: 10px auto 18px;
            border-radius: 51px;
        }
    }


    img {
        pointer-events: none;
    }
}

::v-deep .van-popover__wrapper {
    display: inline;
}


@keyframes flavours {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}
</style>