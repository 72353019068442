<template>
    <div class="choose_button" :class="{'active':isChoose}">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: 'ChooseButton',
    props: {
        text: {
            type: String,
            default: () => '',
        },
        isChoose: {
            type: Boolean,
            default: () => false,
        }
    }
}
</script>

<style scoped lang="less">
.choose_button {
    padding: 10px 15px;
    border: 1px solid #2B7DF7;
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 10px;
    color: #2B7DF7;
    cursor: pointer;
}

.active {
    background-color: #E9F2FE;
}
</style>