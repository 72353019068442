import { render, staticRenderFns } from "./alert_toast.vue?vue&type=template&id=724b5161&scoped=true&"
import script from "./alert_toast.vue?vue&type=script&lang=js&"
export * from "./alert_toast.vue?vue&type=script&lang=js&"
import style0 from "./alert_toast.vue?vue&type=style&index=0&id=724b5161&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724b5161",
  null
  
)

export default component.exports