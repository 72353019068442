<template>
    <div class="sign">
        <div class="sign_title_fixed">
            <img @error="imageError" class="sign_logo" :src="`${$store.state.assetsUrl}/imgs/sign/litely_title_logo.webp`"
                alt="logo" />
            <transition name="lc">
                <div @click="goLogin" v-if="activeMessageIndex <= 3" class="login_button">{{ $t('login') }}</div>
            </transition>
        </div>
        <div class="sign_content" id="sign_content" ref="sign_content">
            <div class="sign_inner">
                <div class="messages" v-for="(item, ind) in messageList" :key="ind">
                    <transition name="lc" @after-enter="listenTransitionend(item.isCoachMessage)">
                        <coach-message v-if="activeMessageIndex >= ind && item.isCoachMessage" :info="item"
                            :username="username" :bmiCount="bmiCount" @coachNext="coachNext"></coach-message>
                        <user-message v-if="activeMessageIndex >= ind && !item.isCoachMessage" :info="item" @next="next"
                            @changeIsMetric="changeIsMetric" :proposeWeight="proposeWeight" :signInfo="signInfo"
                            :weightMax="isMetric ? metricWeightMax : imperialWeightMax" :isMetric="isMetric"></user-message>
                    </transition>
                </div>
                <div class="content_bottom" ref="content_bottom"></div>
            </div>
        </div>
        <div class="preloading_imgs" v-if="activeMessageIndex > 3">
            <img v-for="(item, ind) in preloadingImgsList" :key="ind" :src="item" alt="preloading_imgs">
        </div>
    </div>
</template>
  
<script>
import { requestInitInfo } from '@/api/index.js'
import coachMessage from '@/components/sign_components/coach_message.vue';
import userMessage from '@/components/sign_components/user_message.vue';
import { common } from '@/assets/utils/common.js'
import { myMixin } from '@/assets/mixin/index.js';
import { commonMixin } from '@/assets/mixin/common';
import { logFirebaseEvent } from '@/assets/log/log_manager.js'

export default {
    name: 'sign',
    components: { coachMessage, userMessage },
    mixins: [myMixin, commonMixin],
    data() {
        return {
            // 登录信息
            signInfo: {
                email: "", //电子邮箱，比如 example@qq.com
                user_name: "", //用户名
                sex: 0, //性别,1男，2女，3.trans,0.other
                country_iso: "", //国家码
                gmt_offset: 0, //时区
                birthday: 0, //生日
                fasting_period: {
                    end_hour: 8,
                    start_hour: 16
                }, //断食比例
                health_status: [], //健康状态
                height_key: 175, //体重key
                height_unit: 0, //0.kg,1.lbs
                start_at: 0, //断食开始时间
                weight_init_val: 0, //初始化体重key
                weight_target: 0, //目标体重key
                weight_unit: 0, //体重单位,0kg,1.榜
                bmi: 30,
                aim_items: [], //减肥目标
                know_fasting_type: 1, //断食熟悉程度,1，不太了解，2之前断食过，3.经常断食
                work_hours_every_day: 8 //每天工作小时数
            },
            // 国家码
            country_iso: "US",
            // 当前message的下标
            activeMessageIndex: -1,
            // 用户名
            username: '123',
            // 单位m
            userHeight: 10,
            // 单位kg
            userWeight: 200,
            // 单位kg
            targetWeight: 100,
            // 是否是公制
            isMetric: false,
            // 公制体重最大值
            metricWeightMax: 42,
            // 英制体重最大值
            imperialWeightMax: 89,
            // 实际展示的列表
            messageList: [],
            preloadingImgsList: [
                `${this.$store.state.assetsUrl}/imgs/sign/everyone.webp`,
                `${this.$store.state.assetsUrl}/imgs/sign/fasting_with_coach.webp`,
                `${this.$store.state.assetsUrl}/imgs/sign/ten.webp`,
                `${this.$store.state.assetsUrl}/imgs/sign/eight.webp`,
                `${this.$store.state.assetsUrl}/imgs/sign/birth.webp`,
                `${this.$store.state.assetsUrl}/imgs/sign/vague_plan.webp`,
            ],
            // 本地的总列表
            originalList: [
                // 开场白1
                {
                    content: this.$t('NickChatStep2_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 1,
                },
                // 开场白2
                {
                    content: this.$t('NickChatStep23_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 2,
                },
                // 隐私条款
                {
                    isCoachMessage: true,
                    contentType: 'terms and privacy',
                },
                // 隐私条款选择
                {
                    isCoachMessage: false,
                    inputType: 'agreeChoose',
                    key: 'privacy',
                    value: '',
                    checkList: [
                        this.$t('Agree'),
                        this.$t('Disagree'),
                    ],
                },
                // 询问帮助
                {
                    content: this.$t('NickChatStep70_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 3,
                },
                // 点击选择帮助
                {
                    isCoachMessage: false,
                    inputType: 'helpCheckout',
                    key: 'goal',
                    value: '',
                    checkList: [
                        this.$t('NickChatStep6_ItemTitle'),
                        this.$t('NickChatStep9_ItemTitle'),
                        this.$t('NickChatStep12_ItemTitle'),
                        this.$t('NickChatStep15_ItemTitle'),
                        this.$t('NickChatStep18_ItemTitle'),
                    ],
                    children: [
                        [{
                            content: this.$t('NickChatStep8_ItemTitle'),
                            isCoachMessage: true,
                            contentType: 'text',
                            isChild: true,
                        },
                        {
                            content: this.$t('NickChatStep7_ItemTitle'),
                            isCoachMessage: true,
                            contentType: 'text',
                            isChild: true,
                        },


                        ],
                        [

                            {
                                content: this.$t('NickChatStep11_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },
                            {
                                content: this.$t('NickChatStep10_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },

                        ],
                        [

                            {
                                content: this.$t('NickChatStep14_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },
                            {
                                content: this.$t('NickChatStep13_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },

                        ],
                        [

                            {
                                content: this.$t('NickChatStep17_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },
                            {
                                content: this.$t('NickChatStep16_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },

                        ],
                        [

                            {
                                content: this.$t('NickChatStep20_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },
                            {
                                content: this.$t('NickChatStep19_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },

                        ],
                    ],
                    id: 4,
                },
                // 图片
                {
                    isCoachMessage: true,
                    contentType: 'image',
                    imgUrl: `${this.$store.state.assetsUrl}/imgs/sign/everyone.webp`,
                    scale: 840 / 564,
                    id: 5,
                },
                // 让我们开始吧
                {
                    isCoachMessage: false,
                    inputType: 'radio',
                    key: 'start',
                    value: '',
                    checkList: [
                        this.$t('NickChatStep24_ItemTitle'),
                    ],
                    id: 6,
                },
                // 你对节食知道多少
                {
                    content: this.$t('NickChatStep25_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 7,
                },
                // 回答自己对节食的认知程度
                {
                    isCoachMessage: false,
                    inputType: 'radio',
                    key: 'know',
                    value: '',
                    checkList: [
                        this.$t('NickChatStep26_ItemTitle'),
                        this.$t('NickChatStep28_ItemTitle'),
                        this.$t('NickChatStep31_ItemTitle'),
                    ],
                    children: [
                        [

                            {
                                content: this.$t('NickChatStep67_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },
                            {
                                content: this.$t('NickChatStep27_ItemTitle'),
                                isCoachMessage: true,
                                linkStart: 42,
                                linkLength: 20,
                                linkType: 'ten',
                                contentType: 'text',
                                isChild: true,
                            },

                        ],
                        [

                            {
                                content: this.$t('NickChatStep67_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },
                            {
                                content: this.$t('NickChatStep29_ItemTitle'),
                                isCoachMessage: true,
                                linkStart: 27,
                                linkLength: 17,
                                linkType: 'eight',
                                contentType: 'text',
                                isChild: true,
                            },
                        ],
                        [

                            {
                                content: this.$t('NickChatStep68_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                            },

                        ],
                    ],
                    id: 8,
                },
                // 曲线图
                {
                    isCoachMessage: true,
                    contentType: 'image',
                    key: 'dataLine',
                    imgUrl: `${this.$store.state.assetsUrl}/imgs/sign/fasting_with_coach.webp`,
                    scale: 552 / 426,
                    id: 9,
                },
                // 询问最后一餐的时间
                {
                    content: this.$t('NickChatStep32_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 10,
                },
                // 选择时间
                {
                    isCoachMessage: false,
                    inputType: 'timeSelect',
                    key: 'time',
                    value: '',
                    id: 11,
                },
                // 询问性别
                {
                    content: this.$t('NickChatStep33_ItemTitle'),
                    contentType: 'text',
                    linkStart: 13,
                    linkLength: 24,
                    linkType: 'birth',
                    isCoachMessage: true,
                    id: 12,
                },
                // 男性还是女性
                {
                    isCoachMessage: false,
                    inputType: 'radio',
                    key: 'gender',
                    value: '',
                    checkList: [
                        this.$t('NickChatStep35_ItemTitle'),
                        this.$t('NickChatStep36_ItemTitle'),
                    ],
                    id: 13,
                },
                // 询问年龄
                {
                    content: this.$t('NickChatStep37_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 14,
                },
                // 告知年龄
                {
                    isCoachMessage: false,
                    inputType: 'ageSelect',
                    key: 'age',
                    value: '',
                    id: 15,
                },
                // 询问身高
                {
                    content: this.$t('NickChatStep38_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 16,
                },
                // 告知身高
                {
                    isCoachMessage: false,
                    inputType: 'heightSelect',
                    key: 'height',
                    isMetric: false,
                    value: '',
                    toMetre: '',
                    unit: '',
                    id: 17,
                },
                // 询问体重
                {
                    content: this.$t('NickChatStep65_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 18,
                },
                // 告知体重
                {
                    isCoachMessage: false,
                    inputType: 'weightSelect',
                    isMetric: false,
                    key: 'weight',
                    value: '',
                    unit: '',
                    id: 19,
                    children: [
                        [
                            // 建议2
                            {
                                content: this.$t('NickChatStep40_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                                id: 23,
                            },

                            // 如果当前用户的bmi低于24.9就不建议
                            // 建议1
                            {
                                content: this.$t('NickChatStep39_ItemTitle', { goal: this.proposeWeight }),
                                isCoachMessage: true,
                                contentType: 'text',
                                param: 'proposeWeight',
                                isChild: true,
                                id: 22,
                            },
                            // bmi图
                            {
                                isCoachMessage: true,
                                contentType: 'bmi',
                                key: 'bmi',
                                isChild: true,
                                id: 21,
                            },
                            // 得出DMI
                            {
                                content: this.$t('NickChatStep66_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                                id: 20,
                            },
                        ],
                        [

                            // bmi图
                            {
                                isCoachMessage: true,
                                contentType: 'bmi',
                                key: 'bmi',
                                isChild: true,
                                id: 21,
                            },
                            // 得出DMI
                            {
                                content: this.$t('NickChatStep66_ItemTitle'),
                                isCoachMessage: true,
                                contentType: 'text',
                                isChild: true,
                                id: 20,
                            },
                        ]
                    ]
                },
                // 目标体重
                {
                    content: this.$t('NickChatStep41_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 24,
                },
                // 设置目标体重
                {
                    isCoachMessage: false,
                    inputType: 'targetWeightSelect',
                    key: 'targetWeight',
                    value: '',
                    unit: '',
                    id: 25,
                    isMetric: false,
                    children: [
                        // 如果目标体重>=建议体重 NickChatStep42_ItemTitle 否则 NickChatStep43_ItemTitle
                        [{
                            content: this.$t('NickChatStep42_ItemTitle'),
                            isCoachMessage: true,
                            isChild: true,
                            contentType: 'text',
                        },],
                        [{
                            content: this.$t('NickChatStep43_ItemTitle'),
                            isCoachMessage: true,
                            isChild: true,
                            contentType: 'text',
                        },]
                    ]
                },
                // 一天工作时间
                {
                    content: this.$t('NickChatStep44_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 26,
                },
                // 工作时间选择
                {
                    isCoachMessage: false,
                    inputType: 'radio',
                    key: 'workTime',
                    value: '',
                    checkList: [
                        this.$t('NickChatStep45_ItemTitle'),
                        this.$t('NickChatStep46_ItemTitle'),
                        this.$t('NickChatStep47_ItemTitle'),
                        this.$t('NickChatStep48_ItemTitle'),
                    ],
                    id: 27,
                },
                // 询问姓名
                {
                    content: this.$t('NickChatStep71_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 28,
                },
                // 姓名输入框
                {
                    isCoachMessage: false,
                    inputType: 'username',
                    key: 'username',
                    value: '',
                    id: 29,
                },
                // 健康状况
                {
                    content: this.$t('NickChatStep69_ItemTitle', { username: this.username }),
                    isCoachMessage: true,
                    param: 'username',
                    contentType: 'text',
                    id: 30,
                },
                // 回答健康状况
                {
                    isCoachMessage: false,
                    inputType: 'helpCheckout',
                    key: 'conditions',
                    value: '',
                    checkList: [
                        this.$t('NickChatStep50_ItemTitle'),
                        this.$t('NickChatStep51_ItemTitle'),
                        this.$t('NickChatStep52_ItemTitle'),
                        this.$t('NickChatStep53_ItemTitle'),
                        this.$t('NickChatStep54_ItemTitle'),
                        this.$t('NickChatStep55_ItemTitle'),
                    ],
                    id: 31,
                },
                // cool
                {
                    content: this.$t('NickChatStep56_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 32,
                },
                // 询问email
                {
                    content: this.$t('NickChatStep72_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 33,
                },
                // email
                {
                    isCoachMessage: false,
                    inputType: 'email',
                    key: 'email',
                    value: '',
                    id: 34,
                },
                // 告知等一等
                {
                    content: this.$t('NickChatStep57_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 35,
                },
                // 生成计划等待的文案
                /*
                NickChatStep58_ItemTitle
                NickChatStep59_ItemTitle
                NickChatStep60_ItemTitle
                NickChatStep61_ItemTitle
                NickChatStep62_ItemTitle
                 */
                {
                    content: this.$t('NickChatStep62_ItemTitle', { username: this.username }),
                    isCoachMessage: true,
                    contentType: 'loading',
                    param: 'loading',
                    contentList: [
                        this.$t('NickChatStep58_ItemTitle'),
                        this.$t('NickChatStep59_ItemTitle'),
                        this.$t('NickChatStep60_ItemTitle'),
                        this.$t('NickChatStep61_ItemTitle'),
                    ],
                    id: 36,
                },
                // 最后的激励语言
                {
                    content: this.$t('NickChatStep63_ItemTitle'),
                    isCoachMessage: true,
                    contentType: 'text',
                    id: 37,
                },
                // 计划
                {
                    isCoachMessage: true,
                    contentType: 'plan',
                    id: 38,
                },
            ],
            // 新增的timer
            addTimer: null,
            // 下一个的timer
            nextTimer: null,
            stepTimer: null,
            isScrollPhone: false,
        }
    },
    created() {
        this.$store.commit("setIsApp", true);
        this.initInfo();
        // 进入注册页面的埋点
        logFirebaseEvent('F_Web_Homepage_Show');
    },
    mounted() {
        if (navigator.userAgent.indexOf('iPhone') != -1 && navigator.userAgent.indexOf('Version/15.3') != -1) {
            this.isScrollPhone = true;
        }
    },
    methods: {
        // 初始化信息
        initInfo() {
            // 请求初始化信息
            requestInitInfo().then(res => {
                if (!res.error) {
                    this.country_iso = res.country_iso ?? 'US';
                    let weightInfo = this.messageList.filter(ite => ite.key == 'weight')[0];
                    if (weightInfo != null && weightInfo.value != '') {
                        // 此处已经有了体重的值，公英制已固定，所以这里不设置供英制。
                    } else {
                        if (this.country_iso == 'US') {
                            this.isMetric = false;
                        } else {
                            this.isMetric = true;
                        }
                    }
                    this.signInfo.country_iso = this.country_iso;
                }
            })
            // 获取时区
            this.signInfo.gmt_offset = (new Date().getTimezoneOffset() / -60) * 60 * 60;
            // 获取存储的数据
            let localList = this.$changeLocal.getItem('localList');
            if (localList != null) {
                // 解密+转对象
                this.messageList = localList;
            }
            if (this.messageList.length) {
                // 初始化注册信息
                this.messageList.forEach(item => {
                    this.checkSignInfo(item);
                })
                this.activeMessageIndex = this.messageList.length - 2;
                // 去除掉child的部分
                let originalCount = 0;
                this.messageList.forEach(item => { if (!item.isChild) originalCount++ });
                this.originalList.splice(0, originalCount);
                // 如果最后一条是有children的数据，将originalList添加当前child
                let lastItem = this.messageList[this.messageList.length - 1];
                if (lastItem.children) {
                    this.checkChildren(lastItem);
                }
                setTimeout(() => {
                    this.activeMessageIndex++
                }, 100)
            } else {
                // 初始化加载第一条
                this.addMessageList();
            }
            this.$nextTick(() => {
                let ele = this.$refs.sign_content;
                if (!ele) return;
                ele.scrollTo({ top: ele.scrollHeight, behavior: 'smooth' })
            })
        },
        // 去登录页面
        goLogin() {
            this.$router.push('/login');
        },
        // 添加child
        checkChildren(item) {
            if (item.children) {
                switch (item.key) {
                    case 'goal':
                        this.checkHelp(item);
                        break;
                    case 'know':
                        this.checkAboutFasting(item);
                        break;
                    case 'targetWeight':
                        this.checkTargetWeight(item);
                        break;
                    case 'weight':
                        this.checkBmi(item);
                        break;
                }
            }
        },
        // 添加sign信息
        checkSignInfo(item) {
            var indexList = [];
            if (item.isCoachMessage) return;
            var list = item.value.split('; ');
            switch (item.key) {
                // 邮箱
                case 'email':
                    this.signInfo.email = item.value;
                    break;
                // 健康情况
                case 'conditions':
                    list.forEach(ite => {
                        var index = item.checkList.indexOf(ite.split('.')[0]);
                        if (index != -1) {
                            indexList.push(index);
                        }
                    })
                    this.signInfo.health_status = indexList;
                    break;
                // 用户名
                case 'username':
                    this.signInfo.user_name = item.value;
                    this.username = item.value;
                    break;
                //工作时间
                case 'workTime':
                    list.forEach(ite => {
                        var index = item.checkList.indexOf(ite)
                        if (index != -1) {
                            indexList.push(index);
                        }
                    })
                    this.signInfo.work_hours_every_day = indexList[0];
                    break;
                // 目标体重
                case 'targetWeight':
                    if (item.isMetric) {
                        this.targetWeight = item.value.split('kg')[0] - 0;
                        this.signInfo.weight_target = (this.targetWeight * common.lbs).toFixed(1) * 10;
                    } else {
                        this.targetWeight = item.value.split('lbs')[0] / common.lbs;
                        this.signInfo.weight_target = item.value.split('lbs')[0] * 10;
                    }
                    this.signInfo.weight_unit = item.isMetric ? 0 : 1;
                    break;
                // 初始体重
                case 'weight':
                    var isMetric = item.value.indexOf('kg') != -1;
                    if (isMetric) {
                        this.userWeight = item.value.split('kg')[0] - 0;
                        this.signInfo.weight_init_val = (this.userWeight * common.lbs).toFixed(1) * 10;
                        this.metricWeightMax = item.value.split('.')[0] - 0;
                    } else {
                        this.userWeight = (item.value.split('lb')[0] - 0) / common.lbs;
                        this.signInfo.weight_init_val = item.value.split('lb')[0] * 10;
                        this.imperialWeightMax = item.value.split('.')[0] - 0;
                    }
                    this.isMetric = item.isMetric;
                    break;
                // 身高
                case 'height':
                    this.signInfo.height_key = parseInt(item.toMetre);
                    this.signInfo.height_unit = item.isMetric ? 0 : 1;
                    this.userHeight = (item.toMetre / 100).toFixed(2);
                    break;
                // 年龄
                case 'age':
                    this.signInfo.birthday = this.ageToTimeStamp(item.value);
                    break;
                // 性别
                case 'gender':
                    this.signInfo.sex = item.value == this.$t('NickChatStep35_ItemTitle') ? 1 : 2;
                    break;
                // 最后一餐时间
                case 'time':
                    this.signInfo.start_at = this.lastTimeToTimeStamp(item.value);
                    break;
                // 了解断食的程度
                case 'know':
                    this.signInfo.know_fasting_type = item.checkList.indexOf(item.value) + 1;
                    this.signInfo.fasting_period = item.checkList.indexOf(item.value) ? {
                        end_hour: 10,
                        start_hour: 14
                    } : {
                        end_hour: 8,
                        start_hour: 16
                    }
                    break;
                // 减肥目标
                case 'goal':
                    list.forEach(ite => {
                        var index = item.checkList.indexOf(ite.split('.')[0]);
                        if (index != -1) {
                            indexList.push(index);
                        }
                    })
                    this.signInfo.aim_items = indexList;
                    break;
            }
        },
        // 前往下一项
        coachNext() {
            this.addMessageList();
        },
        // 往message list里面新增
        addMessageList() {
            let target = this.originalList.splice(0, 1)[0];
            if (!target) return;
            this.messageList.push(target);
            clearTimeout(this.nextTimer);
            clearTimeout(this.stepTimer);
            this.nextTimer = setTimeout(() => {
                this.activeMessageIndex++;
                setTimeout(() => {
                    let ele = this.$refs.sign_content;
                    if (!ele) return;
                    const clientHeight = ele.clientHeight;
                    const scrollHeight = ele.scrollHeight;
                    const scrollTop = ele.scrollTop;
                    let step = (scrollHeight - clientHeight - scrollTop) / 100;
                    step = Math.max(1, step);
                    this.scrollToBottom(step)
                }, 200);
            }, 100)
        },
        // 检测动画播放完毕，播放完毕后查看是否插入下一条
        listenTransitionend(isCoachMessage) {
            let lastItem = this.messageList[this.messageList.length - 1];
            if ((lastItem.inputType != null && !lastItem.value) || lastItem.contentType == 'loading') return;
            if (!isCoachMessage) return;
            this.addMessageList();
        },
        // 切换公英制
        changeIsMetric(val) {
            this.isMetric = val;
        },
        // 替换所有的username
        checkUsername() {
            this.originalList.forEach(item => {
                if (item.param == 'username') {
                    item.content = this.$t('NickChatStep69_ItemTitle', { username: this.username });
                } else if (item.param == 'loading') {
                    item.content = this.$t('NickChatStep62_ItemTitle', { username: this.username });
                }
            })
        },
        // 检测选中的帮助
        checkHelp(selectItem) {
            let selectText = selectItem.value;
            if (selectText == '') return;
            let checkList = selectItem.checkList;
            let index = checkList.indexOf(selectText.split('.')[0]);
            index = index == -1 ? 3 : index;
            let addArray = selectItem.children[index];
            addArray.forEach(item => {
                this.originalList.unshift(item);
            })
        },
        // 检测选中的帮助
        checkAboutFasting(selectItem) {
            let selectText = selectItem.value;
            if (selectText == '') return;
            let checkList = selectItem.checkList;
            let index = checkList.indexOf(selectText);
            let addArray = selectItem.children[index];
            addArray.forEach(item => {
                this.originalList.unshift(item);
            })

        },
        // 检测用户选择的体重和建议体重
        checkTargetWeight(selectItem) {
            if (selectItem.value == '') return;
            let isOverProposeWeight = false;
            if (selectItem.value.indexOf('kg') != -1) {
                this.targetWeight = selectItem.value.split('kg')[0] - 0;
                if (selectItem.value.split('kg')[0] - this.proposeWeight.split('kg')[0] >= 0) {
                    isOverProposeWeight = true;
                } else {
                    isOverProposeWeight = false;
                }
            } else {
                this.targetWeight = selectItem.value.split('lbs')[0] * common.lbs;
                if (selectItem.value.split('lbs')[0] - this.proposeWeight.split('lbs')[0] >= 0) {
                    isOverProposeWeight = true;
                } else {
                    isOverProposeWeight = false;
                }
            }
            let index = isOverProposeWeight ? 0 : 1;
            let addArray = selectItem.children[index];
            this.originalList.unshift(addArray[0]);
        },
        // 检查bmi并添加
        checkBmi(selectItem) {
            let selectText = selectItem.value;
            if (selectText == '') return;
            let index = this.bmiCount <= 24.9 ? 1 : 0;
            let addArray = selectItem.children[index];
            addArray.forEach(item => {
                this.originalList.unshift(item);
            })
        },
        // firebase数据埋点
        firebaseLog(item) {
            if (item.key == 'gender') {
                // 选择性别埋点
                logFirebaseEvent('F_Web_Dialogue_Sex');
            } else if (item.key == 'username') {
                // 填写名字埋点
                logFirebaseEvent('F_Web_Dialogue_Name');
            } else if (item.key == 'email') {
                // 填写email埋点
                logFirebaseEvent('F_Web_Dialogue_Email');
            } else if (item.key == 'conditions') {
                // 选择健康情况埋点
                logFirebaseEvent('F_Web_Dialogue_Health');
            } else if (item.key == 'goal') {
                // 选择目标埋点
                logFirebaseEvent('F_Web_Dialogue_Goal');
            }
        },
        // 下一步
        next(val) {
            // 设置消息列表之前的预处理
            if (val.key == 'weight') {
                if (this.isMetric) {
                    this.userWeight = val.value.split('kg')[0] - 0;
                    this.metricWeightMax = val.value.split('.')[0] - 0;
                } else {
                    this.userWeight = (val.value.split('lb')[0] - 0) / common.lbs;
                    this.imperialWeightMax = val.value.split('.')[0] - 0;
                }
                this.checkBmi(val);
            } else if (val.key == 'height') {
                this.userHeight = (val.toMetre / 100).toFixed(2);
            } else if (val.key == 'username') {
                this.username = val.value;
                this.checkUsername();
            } else if (val.key == 'goal') {
                this.checkHelp(val);
            } else if (val.key == 'know') {
                this.checkAboutFasting(val);
            } else if (val.key == 'targetWeight') {
                this.checkTargetWeight(val);
            }
            this.firebaseLog(val);
            let messageList = JSON.parse(JSON.stringify(this.messageList))
            messageList.forEach(item => {
                if (item.key == val.key) {
                    for (let key in val) {
                        item[key] = val[key]
                    }
                }
            })
            this.messageList = messageList;
            // 将信息写入signInfo
            this.checkSignInfo(val);
            // 存储到本地  转JSON+解密
            this.$changeLocal.setItem('localList', this.messageList);
            clearTimeout(this.addTimer);

            this.addTimer = setTimeout(() => {
                this.addMessageList();
            }, 1000)

        },
        // 滚动到底部动画
        scrollToBottom(step) {
            if (this.isScrollPhone) {
                this.$nextTick(() => {
                    let ele = this.$refs.sign_content;
                    if (!ele) return;
                    ele.scrollTop += step;
                    const clientHeight = ele.clientHeight || document.body.clientHeight;
                    const scrollHeight = ele.scrollHeight;
                    const height = scrollHeight - clientHeight;
                    if (ele.scrollTop < height) {
                        this.stepTimer = setTimeout(() => this.scrollToBottom(step), 1);
                    } else {
                        clearTimeout(this.stepTimer);
                    }
                })
            } else {
                let ele = this.$refs.sign_content;
                if (!ele) return;
                this.$nextTick(() => {
                    ele.scrollTo({ top: ele.scrollHeight, behavior: 'smooth' })
                })
            }
        },
        //根据年龄算日期
        ageToTimeStamp(ageYear) {
            let subYear = parseInt(ageYear);
            let now = new Date();
            let nowYear = now.getFullYear();
            let year = nowYear - subYear;
            return new Date(`${year}-1-1`).getTime();
        },
        lastTimeToTimeStamp(timeString) {
            let now = new Date();
            let timeInfo = timeString.split(' ');
            let time = timeInfo[0].split(':');
            let hour = time[0] - 0;
            let minute = time[1];
            if (timeInfo[1] == 'PM') {
                hour += 12;
            }
            hour = hour == 24 ? '00' : hour;
            return new Date(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${hour}:${minute}:00`).getTime();
        },
        // 检查数据并赋值
        checkBmiText() {
            this.originalList = this.originalList.map(item => {
                if (item.param == 'proposeWeight') {
                    item.content = this.$t('NickChatStep39_ItemTitle', { goal: this.proposeWeight });
                }
                return item;
            })
        }
    },
    watch: {
        messageList() {
            // 转JSON+解密
            this.$changeLocal.setItem('localList', this.messageList);
        },
        bmiCount(val) {
            this.signInfo.bmi = parseInt(val.toFixed(1));
        },
        proposeWeight() {
            if (this.proposeWeight != '') {
                this.checkBmiText();
            }
        }
    },
    computed: {
        // 生成建议体重
        proposeWeight() {
            let kilogramWeight = 24.9 * this.userHeight * this.userHeight;
            if (this.messageList.length == 0) return;
            let weightSelect = this.messageList.filter((ite) => ite.inputType == 'weightSelect')[0];
            if (!weightSelect || (weightSelect && !weightSelect.value)) return '';
            let isMetric = weightSelect.value.indexOf('kg') != -1;
            if (isMetric) {
                let userWeight = weightSelect.value.split('kg')[0];
                if (userWeight) {
                    kilogramWeight = Math.round(Math.min(kilogramWeight, userWeight - 1))
                }
                return kilogramWeight.toFixed(1) + 'kg';
            } else {
                kilogramWeight = Math.round(kilogramWeight * common.lbs);
                let userWeight = weightSelect.value.split('lbs')[0];
                if (userWeight) {
                    kilogramWeight = Math.round(Math.min(kilogramWeight, userWeight - 1))
                }
                return kilogramWeight.toFixed(1) + 'lbs';
            }
        },
        // 生成bmi数据
        bmiCount() {
            return this.userWeight / (this.userHeight * this.userHeight);
        },
    }

}
</script>

<style scoped lang="less">
.sign {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;


    .sign_title_fixed {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        height: 60px;
        justify-content: space-between;
        padding: 0 18px;
        align-items: center;
        max-width: 765px;
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        z-index: 10;

        .sign_logo {
            width: 65px;
            height: 26px;
        }

        img {
            pointer-events: none;
        }

        .login_button {
            width: 74px;
            height: 28px;
            border-radius: 28px;
            background-color: #F3F7F8;
            font-size: 12px;
            font-family: 'AvenirNext';
            font-weight: 500;
            color: rgba(94, 103, 129, 0.8);
            display: flex;
            justify-content: center;
            align-content: center;
            line-height: 28px;
            cursor: pointer;
        }
    }

    .sign_content {
        padding-top: 60px;
        // flex: 1;
        height: 100vh;
        overflow-y: auto;
        box-sizing: border-box;

        .sign_inner {
            max-width: 765px;
            margin: 0 auto;
        }

        .content_bottom {
            height: 200px;
        }
    }

    .preloading_imgs {
        display: none;
    }
}

.lc-enter,
.lc-leave-to {
    opacity: 0;
}

.lc-enter-to,
.lc-leave {
    opacity: 1;
}

.lc-enter-active,
.lc-leave-active {
    transition: all 1s;
}
</style>