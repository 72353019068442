

<template>
    <div class="alert_toast">
        <div class="title">
            {{ alertInfo.title }}
        </div>
        <div class="content">
            <loading-image :width="'90px'" :height="'90px'" :imageUrl="alertInfo.url"></loading-image>
            <div class="alert_text">{{ alertInfo.content }}</div>
        </div>
    </div>
</template>

<script>


import LoadingImage from '../loading_image.vue';
export default {
    name: 'alert_toast',
    components: { LoadingImage },
    props: {
        type: {
            type: String,
            default: () => '',
        }
    },
    data() {
        return {
            alertInfo: {
                title: '',
                content: '',
                url: '',
            },
            tenAlertInfo: {
                title: this.$t('Register_Dialog_14_ItemTitle'),
                content: this.$t('Register_Dialog_14_content_ItemTitle'),
                url: `${this.$store.state.assetsUrl}/imgs/sign/ten.webp`,
            },
            eightAlertInfo: {
                title: this.$t('Register_Dialog_16_ItemTitle'),
                content: this.$t('Register_Dialog_16_content_ItemTitle'),
                url: `${this.$store.state.assetsUrl}/imgs/sign/eight.webp`,
            },
            birthAlertInfo: {
                title: this.$t('Register_Dialog_gender_ItemTitle'),
                content: this.$t('Register_Dialog_gender_content_ItemTitle'),
                url: `${this.$store.state.assetsUrl}/imgs/sign/birth.webp`,
            },
        }
    },
    created() {
        this.handleType();
    },
    mounted() {
    },
    methods: {
        handleType() {
            if (this.type == 'ten') {
                this.alertInfo = this.tenAlertInfo;
            } else if (this.type == 'eight') {
                this.alertInfo = this.eightAlertInfo;
            } else if (this.type == 'birth') {
                this.alertInfo = this.birthAlertInfo;
            }
        }
    }
}
</script>

<style scoped lang="less">
.alert_toast {
    max-width: 375px;
    padding: 15px;

    .title {
        position: relative;
        height: 22px;
        padding-left: 8px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
    }

    .title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 22px;
        width: 3px;
        background-color: #5D99E9;
    }

    .content {
        display: flex;
        margin-top: 18px;

        img {
            width: 90px;
            height: 90px;
        }

        .alert_text {
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            margin-left: 10px;
            line-height: 18px;
            color: #333333;
        }
    }


    img {
        pointer-events: none;
    }
}
</style>